/* global Office */
// Required for slow networks. MS code is waiting for Office to be initialized using a timeout loop.
// Have to set Office.initialize to a dummy function BEFORE calling Office.onReady()
// eslint-disable-next-line office-addins/no-office-initialize
Office.initialize = () => {};

// @ts-ignore
import bootstrap from "./bootstrap";
bootstrap(() => {});

export {};
